import React from 'react'
import { navigate } from 'gatsby'
import { graphql } from 'gatsby'
import { filter, isEmpty, head} from 'lodash'
import { passwordProtected } from '../config/password-proctected'
import PageContentWrapper from '../components/pageContentWrapper'
import HousingTypesChart from '../components/charts/housing-types.js'
import HousingApprovalsChart from '../components/charts/housing-approvals.js'
import HousingTypesByBedroomChart from '../components/charts/housing-types-by-bedroom.js'
import HousingTypesByBedroomChangeChart from '../components/charts/housing-types-by-bedroom-change.js'
import DynamicText from '../components/dynamicText'
import DominantHousingTypeInfo from '../components/dynamicText/housing-supply/dominant-housing-type'
import CrossLink from '../components/crossLink'
import ApprovalsBySA1Map from '../components/maps/approvalsBySA1'

class DwellingsTemplate extends React.Component {
  componentDidMount() {
    const access = head(
      filter(passwordProtected, ['lga', this.props.pageContext.LGASlug])
    )
    if (!isEmpty(access) && access.password !== window.pass) {
      navigate(`/${this.props.pageContext.LGASlug}`)
    }
  }

  render() {
    const title = this.props.pageContext.title
    const slug = this.props.pageContext.slug
    const LGAName = this.props.pageContext.lgaName
    const LGALongName = this.props.data.areas2XlsxAreas.LongName
    const alias = this.props.data.areas2XlsxAreas.Alias
    const geocode = this.props.pageContext.geocode
    const benchmarkName = this.props.data.areas2XlsxAreas.GCC_Name

    

    const dwellingTypeCrossLinks = [
      {
        title: `Map of separate houses`,
        url: `https://atlas.id.com.au/${alias}/maps/separate-houses`,
      },
      {
        title: `Map of medium density housing `,
        url: `https://atlas.id.com.au/${alias}/maps/medium-density-housing`,
      },
      {
        title: `Map of high density housing `,
        url: `https://atlas.id.com.au/${alias}/maps/high-density-housing`,
      },
    ]

    return (
      <PageContentWrapper
        title={title}
        LGAName={LGALongName}
        alias={alias}
        theme={this.props.pageContext.theme}
        areas={this.props.data.areas2XlsxAreas}
        slug={slug}
        productLinks={this.props.pageContext.productLinks}
        geocode={geocode}
        clientLogo={this.props.data.allFile}
      >
        <h2 className="section-heading mt-0">Dwelling Types</h2>
        <div className="row">
          <div className="col-12 anchor-point" id="what-is-the-dominant-housing-type">
            <HousingTypesChart
              data={this.props.data.dwellingStructure}
              benchmarkData={this.props.data.dwellingStructure_BM}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              dataNotes={this.props.data.housingTypesNotes}
              chartInfo={
                DynamicText(
                 <DominantHousingTypeInfo benchmarkName={benchmarkName} LGAName={LGAName} />
                )}
                />
          </div>
          <div className="col-12">
            <CrossLink 
              urls={dwellingTypeCrossLinks} 
              product="atlas" 
              icon="map"
              clientProducts={this.props.pageContext.productLinks}
              >
              <div className="cross-link__title">
                Where are different dwelling types most prominent?
              </div>
              <div className="cross-link__sub-title">Collection of maps</div>
            </CrossLink>
          </div>
        </div>

        <div className="row">
          <div className="col-12 anchor-point" id="what-is-the-mix-of-housing">
            <HousingTypesByBedroomChart
              data={this.props.data.dwellingType}
              areaName={LGAName}
              dataNotes={this.props.data.housingTypesByBedroomsNotes}
              chartInfo={this.props.data.housingMixInfo}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <HousingTypesByBedroomChangeChart
              data={
                this.props.data
                  .allDwellingTypeBedroomsXlsxDwellingStructureByBedrooms
              }
              areaName={LGAName}
              dataNotes={this.props.data.housingTypesByBedroomsNotes}
              chartInfo={this.props.data.housingMixInfo}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 anchor-point" id="how-are-residential-building-approvals-tracking">
            <HousingApprovalsChart
              data={this.props.data.approvals_lga}
              dataNotes={this.props.data.housingApprovalsNotes}
              chartInfo={this.props.data.buildingApprovalsInfo}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 anchor-point" id="where-is-new-building-happening">
            <ApprovalsBySA1Map
              data={this.props.data.approvals_sa1.nodes}
              chartInfo={this.props.data.buildingApprovalsLocatedInfo}
              dataNotes = {this.props.data.buildingApprovalsLocatedMapInfo}
              pageContext={this.props.pageContext}
            />
          </div>

        </div>
      </PageContentWrapper>
    )
  }
}

export default DwellingsTemplate

export const PageQuery = graphql`
  query HousingTypesByLGA(
    $lgacode: String!
    $geocode: String!
    $benchmarkCode: String!
    $fullSA1List: [String]
  ) {
    dwellingStructure: allDwellingStuctureXlsxDwellingStructure(
      filter: { Area_Id: { eq: $lgacode } }
    ) {
      edges {
        node {
          Area_Id
          n2021
          per2021
          n2016
          per2016
          n2011
          per2011
          n2006
          per2006
          DwellingStructure
        }
      }
    }
    dwellingStructure_BM: allDwellingStuctureXlsxDwellingStructure(
      filter: { Area_Id: { eq: $benchmarkCode } }
    ) {
      edges {
        node {
          Area_Id
          n2021
          per2021
          n2016
          per2016
          n2011
          per2011
          n2006
          per2006
          DwellingStructure
        }
      }
    }
    dwellingType: allDwellingTypeBedroomsXlsxDwellingStructureByBedrooms(
      filter: { Area_ID: { eq: $lgacode } }
      sort: {fields: DwellingStructure, order: DESC}
    ) {
      edges {
        node {
          DwellingStructure
          fields {
            BedroomNumber
          }
          Num2021
          Per2021
        BenchmarkName
          bPer2021
          bNum2021
          id
        }
      }
    }

    allDwellingTypeBedroomsXlsxDwellingStructureByBedrooms(
      filter: { Area_ID: { eq: $lgacode } }
      sort: {fields: DwellingStructure, order: DESC}
    ) {
      edges {
        node {
          Area_Name
          BenchmarkCode
          DwellingStructure
          Area_ID
          BenchmarkName
          fields {
            BedroomNumber
          }
          Num2021
          Num2021
          Per2021
          Per2021
          Total2021
          Total2021
          Num2021
          Per2021
          _2016_2021_change_per
          _2016_2021_change_num
        }
      }
    }

    areas2XlsxAreas(Area_Id: { eq: $lgacode }) {
      Name
      LongName
      Geocode
      Area_Id
      GCC_Code
      GCC_Name
      Alias
    }

    approvals_lga: allBuildingApprovalsLga(
      filter: {lga: {eq: $lgacode}}
      sort: { fields: [data___LGA___Year], order: ASC }
    ) {
      nodes {
        data {
          LGA {
          Number_House
          Number_Other
          Number_Total
          Year_Label
          Year
          }
        }
      }
    }


    approvals_sa1: allBuildingApprovalsSa1(
      filter: {sa1: {in: $fullSA1List}}
    ) {
      nodes {
        data {
          Approvals_Per_Quarter {
            Houses
            Other_residential
            Period
            Total_Residential
            }
          }
        sa1
      }
    }

    allFile(filter: { name: { eq: $geocode } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
   
    housingTypesNotes: markdownRemark(
      frontmatter: { slug: { eq: "housing-types" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    housingTypesByBedroomsNotes: markdownRemark(
      frontmatter: {
        slug: { eq: "housing-types-by-bedrooms" }
        type: { eq: "full-text" }
      }
    ) {
      ...DataNoteFragment
    }

    housingApprovalsNotes: markdownRemark(
      frontmatter: {
        slug: { eq: "housing-approvals" }
        type: { eq: "full-text" }
      }
    ) {
      ...DataNoteFragment
    }

    housingMixInfo: markdownRemark(
      frontmatter: {
        slug: { eq: "housing-mix" }
        type: { eq: "chart-info" }
      }
    ) {
      ...DataNoteFragment
    }

    buildingApprovalsInfo: markdownRemark(
      frontmatter: {
        slug: { eq: "building-approvals" }
        type: { eq: "chart-info" }
      }
    ) {
      ...DataNoteFragment
    }

    buildingApprovalsMapInfo: markdownRemark(
      frontmatter: {
        slug: { eq: "building-approvals-map" }
        type: { eq: "chart-info" }
      }
    ) {
      ...DataNoteFragment
    }

    buildingApprovalsLocatedInfo: markdownRemark(
      frontmatter: {
        slug: { eq: "building-approvals-located" }
        type: { eq: "chart-info" }
      }
    ) {
      ...DataNoteFragment
    }

    buildingApprovalsLocatedMapInfo: markdownRemark(
      frontmatter: {
        slug: { eq: "building-approvals-located-map" }
        type: { eq: "chart-info" }
      }
    ) {
      ...DataNoteFragment
    }
  }
`
