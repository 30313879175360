import { isEmpty, max } from 'lodash'
import React, { useContext } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { scaleLinear } from 'd3-scale'
import Moment from 'moment'
import ChartWrapper from './../chartWrapper'
import PillSelect from '../pillSelect'
import TimePeriodDropdown from '../timePeriodDropdown'
import { ErrorMessageChart } from '../../components/error-message-chart'
import HouseholdTypeDropdown from '../householdTypeDropdown'
import { AvailabilityContext } from '../../hooks/availabilityContext'
import { formatNumberNA, formatNumber } from '../../functions/formatNumber'
import { getEntryLevelLabel } from '../../functions/getLevelLabels'
import CustomHorizontalLabel from '../customLabel/CustomHorizontalLabel'

const HousingAffordabilityLgaChart = ({
  alias,
  pricePoints,
  incomeRangesByYear,
  name,
  title,
  dataSource,
  dataNotes,
  type,
  proptrackDisclaimer,
  xoffset =0
}) => {
  const context = useContext(AvailabilityContext)
  try {
    if (isEmpty(pricePoints) || isEmpty(incomeRangesByYear)) {
      return <ErrorMessageChart error="No data for chart" />
    }

    const periods = pricePoints.Periods.sort((a, b) =>
      Moment(b.Period_Name, 'MMM YYYY').diff(Moment(a.Period_Name, 'MMM YYYY'))
    ).map(p => p.Period_Name)
    const activePeriod = context.activePeriod == null ? periods[0] : context.activePeriod
    const currentPricePoints = pricePoints.Periods.find(p => p.Period_Name == activePeriod)
    const incomes = incomeRangesByYear.nodes[0].data.Incomes.find(
      r => r.Year == Moment(activePeriod, 'MMM YYYY').year()
    )
    const interest = incomeRangesByYear.nodes[0].data.Interest_rate

    let ranges
    if (type === 'mortgage') {
      ranges = {
        Very_Low: getPresentValueMortgage(incomes[context.householdType].Very_Low, interest),
        Low: getPresentValueMortgage(incomes[context.householdType].Low, interest),
        Moderate: getPresentValueMortgage(incomes[context.householdType].Moderate, interest),
      }
    } else {
      ranges = {
        Very_Low: (incomes[context.householdType].Very_Low * 0.3) / 52,
        Low: (incomes[context.householdType].Low * 0.3) / 52,
        Moderate: (incomes[context.householdType].Moderate * 0.3) / 52,
      }
    }

    const handleActivePeriodDropdownOnChange = event => {
      context.setActivePeriod(event.target.value)
    }

    const handleHouseHoldTypeDropdownOnChange = event => {
      context.setHouseholdType(event.target.value)
    }

    const SubTitle = () => {
      const mortgageText = `What households can afford vs ${getEntryLevelLabel(
        alias
      ).toLowerCase()} and median property prices`
      const rentalText = `What households can afford in rent vs ${getEntryLevelLabel(
        alias
      ).toLowerCase()} and median market prices ($ pw)`

      return (
        <>
          {type === 'mortgage' ? mortgageText : rentalText} in the 12 months to{' '}
          {periods.length > 1 && (
            <TimePeriodDropdown
              onChange={handleActivePeriodDropdownOnChange}
              periods={periods}
              currentPeriod={activePeriod}
            />
          )}
          {periods.length === 1 && <PillSelect>{activePeriod}</PillSelect>} for{' '}
          <HouseholdTypeDropdown
            onChange={handleHouseHoldTypeDropdownOnChange}
            value={context.householdType}
          />
        </>
      )
    }

    const chartData = [
      {
        name: 'Very low income',
        range: [0, ranges.Very_Low],
        fill:
          typeof window !== `undefined` &&
          getComputedStyle(document.body).getPropertyValue('--bs-primary'),
      },
      {
        name: 'Low income',
        range: [0, ranges.Low],
        fill:
          typeof window !== `undefined` &&
          getComputedStyle(document.body).getPropertyValue('--bs-muted20'),
      },
      {
        name: 'Moderate income',
        range: [0, ranges.Moderate],
        fill:
          typeof window !== `undefined` &&
          getComputedStyle(document.body).getPropertyValue('--bs-muted'),
      },
    ]

    const chartBMLines = {
      House_Median: currentPricePoints.Median_House,
      House_Entry: currentPricePoints.Entry_House,
      Unit_Entry: currentPricePoints.Entry_Unit,
      Unit_Median: currentPricePoints.Median_Unit,
    }

    const maxValue = max([
      chartData[2].range[1],
      chartBMLines.House_Median,
      chartBMLines.Unit_Median,
    ])

    const domain = scaleLinear()
      .domain([0, maxValue])
      .nice(5)
      .domain()

    const legendContent = [
      {
        id: 'Entry Unit: ',
        type: 'plainline',
        payload: {
          strokeDasharray: '3 3',
        },
        color: 'black',
        value: `${getEntryLevelLabel(alias)} Unit: ${formatNumberNA(
          chartBMLines.Unit_Entry,
          '$0,0'
        )}`,
      },
      {
        id: 'Median Unit: ',
        type: 'plainline',
        payload: {
          strokeDasharray: '3 3',
        },
        color: 'black',
        value: `Median Unit: ${formatNumberNA(chartBMLines.Unit_Median, '$0,0')}`,
      },
      {
        id: 'Entry House: ',
        type: 'plainline',
        payload: {
          strokeDasharray: '1 0',
        },
        color: 'black',
        value: `${getEntryLevelLabel(alias)} House: ${formatNumberNA(
          chartBMLines.House_Entry,
          '$0,0'
        )}`,
      },
      {
        id: 'Median House: ',
        type: 'plainline',
        payload: {
          strokeDasharray: '1 0',
        },
        color: 'black',
        value: `Median House: ${formatNumberNA(chartBMLines.House_Median, '$0,0')}`,
      },
    ]
    const ticks = scaleLinear()
      .domain(domain)
      .nice(4)
      .ticks(4)

    return (
      <ChartWrapper
        title={title}
        subTitle={<SubTitle />}
        dataSource={dataSource}
        dataNotes={dataNotes}
        name={name}
        proptrackDisclaimer={proptrackDisclaimer}
        body={
          <div className="chart__viz" style={{ height: 250 }}>
            <ResponsiveContainer>
              <BarChart data={chartData} margin={{ bottom: 15, right: 40 }} layout="vertical">
                <YAxis type="category" dataKey="name" width={100} tickLine={false} />
                <XAxis
                  type="number"
                  tickLine={false}
                  ticks={ticks}
                  tickFormatter={value => formatNumber(value,'$0,0')}
                />
                <CartesianGrid vertical={true} horizontal={false} />
                <Legend
                  align="left"
                  verticalAlign="top"
                  height={60}
                  width={430}
                  payload={legendContent}
                  formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                />
                <Bar dataKey="range" isAnimationActive={false}
                  label={props => (
                    <CustomHorizontalLabel {...props} xoffset={xoffset} formatter={(value) => formatNumber(value,'$0,0')} fill={null}/>
                  )}
                />
                <ReferenceLine x={chartBMLines.House_Median} stroke="black" />
                <ReferenceLine x={chartBMLines.Unit_Entry} stroke="black" strokeDasharray="3 3" />
                <ReferenceLine x={chartBMLines.House_Entry} stroke="black" />
                <ReferenceLine x={chartBMLines.Unit_Median} stroke="black" strokeDasharray="3 3" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        }
      />
    )
  } catch (error) {
    return <ErrorMessageChart error={error} />
  }
}
const getPresentValueMortgage = (income, interest) => {
  return (((income * 0.3) / 12) * (1 - (1 + interest / 12) ** (-30 * 12))) / (interest / 12) / 0.8
}
export default HousingAffordabilityLgaChart
