/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import '@babel/polyfill'
import React from 'react'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/scss/notification.scss'
import 'animate.css'
import AvailabilityProvider from './src/hooks/availabilityContext'

export const wrapRootElement = ({ element }) => (
  <AvailabilityProvider>
    <ReactNotification />
    {element}
  </AvailabilityProvider>
)
