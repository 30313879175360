import React from 'react'
import { navigate } from 'gatsby'
import { graphql } from 'gatsby'
import { filter, isEmpty, head } from 'lodash'
import { passwordProtected } from '../config/password-proctected'
import PageContentWrapper from '../components/pageContentWrapper'
import HouseholdTypeChart from '../components/charts/household-type.js'
import AgeGroupsChart from '../components/charts/age-groups.js'
import AgeChangeChart from '../components/charts/age-change-chart.js'
import PopulationForecastChart from '../components/charts/population-forecast.js'
import TenureChart from '../components/charts/tenure.js'
import TenureChangeChart from '../components/charts/tenure-change.js'
import HouseholdForecastChart from '../components/charts/household-forecast.js'
import DynamicText from '../components/dynamicText'
import PopulationChangeInfo from '../components/dynamicText/population-households/population-chage'
import CrossLink from '../components/crossLink'

class PopulationHouseholdsTemplate extends React.Component {
  componentDidMount() {
    const access = head(filter(passwordProtected, ['lga', this.props.pageContext.LGASlug]))
    if (!isEmpty(access) && access.password !== window.pass) {
      navigate(`/${this.props.pageContext.LGASlug}`)
    }
  }

  render() {
    const title = this.props.pageContext.title
    const slug = this.props.pageContext.slug
    const LGAName = this.props.data.areas2XlsxAreas.Name
    const LGALongName = this.props.data.areas2XlsxAreas.LongName
    const alias = this.props.data.areas2XlsxAreas.Alias
    const benchmarkName = this.props.data.areas2XlsxAreas.GCC_Name

    const tenureCrossLinks = [
      {
        title: `Map of households renting privately`,
        url: `https://atlas.id.com.au/${alias}/maps/renting-privately`,
      },
      {
        title: `Map of households renting social housing `,
        url: `https://atlas.id.com.au/${alias}/maps/renting-social-housing`,
      },
      {
        title: `Map of households with a mortgage `,
        url: `https://atlas.id.com.au/${alias}/maps/households-with-a-mortgage`,
      },
      {
        title: `Map of households who fully own their dwelling `,
        url: `https://atlas.id.com.au/${alias}/maps/home-owners`,
      },
    ]

    const householdCrossLinks = [
      {
        title: `Map of couples with children `,
        url: `https://atlas.id.com.au/${alias}/maps/couple-families-with-children`,
      },
      {
        title: `Map of couples with young children (aged under 15 years)`,
        url: `https://atlas.id.com.au/${alias}/maps/couple-families-dependent-children`,
      },
      {
        title: `Map of one parent families`,
        url: `https://atlas.id.com.au/${alias}/maps/one-parent-families-with-children `,
      },
      {
        title: `Map of one parent families with young children (aged under 15 years)`,
        url: `https://atlas.id.com.au/${alias}/maps/one-parent-families-dependent-children`,
      },
      {
        title: `Map of couples without children `,
        url: `https://atlas.id.com.au/${alias}/maps/couple-families-without-children`,
      },
      {
        title: `Map of older couples without children`,
        url: `https://atlas.id.com.au/${alias}/maps/older-couples-without-children`,
      },
      {
        title: `Map of young couples without children`,
        url: `https://atlas.id.com.au/${alias}/maps/young-couples-without-children`,
      },
      {
        title: `Map of lone person households`,
        url: `https://atlas.id.com.au/${alias}/maps/lone-person-households`,
      },
      {
        title: `Map of older lone person households`,
        url: `https://atlas.id.com.au/${alias}/maps/older-lone-person-households`,
      },
      {
        title: `Map of young lone person households`,
        url: `https://atlas.id.com.au/${alias}/maps/young-lone-person-households`,
      },
      {
        title: `Map of group households `,
        url: `https://atlas.id.com.au/${alias}/maps/group-households`,
      },
    ]

    const ageGroupCrossLinks = [
      {
        title: `Map of population aged 0-4 years`,
        url: `https://atlas.id.com.au/${alias}/maps/aged-0-to-4-years`,
      },
      {
        title: `Map of population aged 5-11 years`,
        url: `https://atlas.id.com.au/${alias}/maps/aged-5-to-11-years`,
      },
      {
        title: `Map of population aged 12-17 years`,
        url: `https://atlas.id.com.au/${alias}/maps/aged-12-to-17-years`,
      },
      {
        title: `Map of population aged 18-24 years`,
        url: `https://atlas.id.com.au/${alias}/maps/aged-18-to-24-years`,
      },
      {
        title: `Map of population aged 25-34 years`,
        url: `https://atlas.id.com.au/${alias}/maps/aged-25-to-34-years`,
      },
      {
        title: `Map of population aged 35-49 years`,
        url: `https://atlas.id.com.au/${alias}/maps/aged-35-to-49-years`,
      },
      {
        title: `Map of population aged 50-59 years`,
        url: `https://atlas.id.com.au/${alias}/maps/aged-50-to-59-years`,
      },
      {
        title: `Map of population aged 60-69 years`,
        url: `https://atlas.id.com.au/${alias}/maps/aged-60-to-69-years`,
      },
      {
        title: `Map of population aged 70-84 years`,
        url: `https://atlas.id.com.au/${alias}/maps/aged-70-to-84-years`,
      },
      {
        title: `Map of population aged 85 years and over`,
        url: `https://atlas.id.com.au/${alias}/maps/aged-85-years-and-over`,
      },
    ]

    return (
      <PageContentWrapper
        title={title}
        LGAName={LGALongName}
        theme={this.props.pageContext.theme}
        areas={this.props.data.areas2XlsxAreas}
        slug={slug}
        alias={alias}
        productLinks={this.props.pageContext.productLinks}
        clientLogo={this.props.data.allFile}
      >
        <h2 className="section-heading mt-0">Population forecasts and age profile</h2>
        <div className="row">
          <div className="col-12 anchor-point" id="how-is-the-population-changing">
            <PopulationForecastChart
              data={this.props.data.populationForecast}
              areaName={LGAName}
              color={
                typeof window !== `undefined` &&
                getComputedStyle(document.body).getPropertyValue('--bs-primary')
              }
              dataNotes={this.props.data.populationForecastNotes}
              chartInfo={DynamicText(<PopulationChangeInfo LGAName={LGAName} />)}
            />
          </div>
          <div className="col-12">
            <CrossLink
              urls={[`https://forecast.id.com.au/${alias}/population-age-structure-map`]}
              product="forecast"
              icon="map"
              clientProducts={this.props.pageContext.productLinks}
            >
              <div className="cross-link__title">Where is the population forecast to grow?</div>
              <div className="cross-link__sub-title">Map of population and age structure</div>
            </CrossLink>
          </div>
        </div>

        <div className="row">
          <div className="col-12 anchor-point" id="what-is-the-age-profile">
            <AgeGroupsChart
              data={this.props.data.ageGroups}
              benchmarkData={this.props.data.ageGroupsBM}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              color={
                typeof window !== `undefined` &&
                getComputedStyle(document.body).getPropertyValue('--bs-primary')
              }
              dataNotes={this.props.data.populationAgeNotes}
              chartInfo={this.props.data.populationAgeInfo}
            />
          </div>
          <div className="col-12 anchor-point" id="how-is-the-age-profile-changing">
            <AgeChangeChart
              data={this.props.data.ageChange}
              areaName={LGAName}
              color={
                typeof window !== `undefined` &&
                getComputedStyle(document.body).getPropertyValue('--bs-primary')
              }
              dataNotes={this.props.data.populationAgeNotes}
              chartInfo={this.props.data.populationAgeInfo}
            />
          </div>
          <div className="col-12">
            <CrossLink
              urls={[`https://forecast.id.com.au/${alias}/population-age-structure`]}
              product="forecast"
              clientProducts={this.props.pageContext.productLinks}
            >
              <div className="cross-link__title">How are age groups forecast to grow?</div>
              <div className="cross-link__sub-title">Forecast population and age structure</div>
            </CrossLink>

            <CrossLink
              urls={ageGroupCrossLinks}
              product="atlas"
              icon="map"
              clientProducts={this.props.pageContext.productLinks}
            >
              <div className="cross-link__title">Where do different age groups live?</div>
              <div className="cross-link__sub-title">Collection of maps</div>
            </CrossLink>
          </div>
        </div>

        <div className="row">
          <div className="col-12 anchor-point" id="what-is-the-mix-of-household-types">
            <HouseholdTypeChart
              data={this.props.data.householdsXlsxHouseholdTypes}
              benchmarkData={this.props.data.householdTypesBM}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              color={
                typeof window !== `undefined` &&
                getComputedStyle(document.body).getPropertyValue('--bs-primary')
              }
              dataNotes={this.props.data.householdTypeNotes}
              chartInfo={this.props.data.householdTypeInfo}
            />
          </div>
          <div className="col-12">
            <CrossLink
              urls={householdCrossLinks}
              product="atlas"
              icon="map"
              clientProducts={this.props.pageContext.productLinks}
            >
              <div className="cross-link__title">Where do these household types live?</div>
              <div className="cross-link__sub-title">Collection of maps</div>
            </CrossLink>

            <CrossLink
              urls={[`https://forecast.id.com.au/${alias}/household-types-map`]}
              product="forecast"
              icon="map"
              clientProducts={this.props.pageContext.productLinks}
            >
              <div className="cross-link__title">
                Where are these household types forecast to grow?
              </div>
              <div className="cross-link__sub-title">Map of forecast household types</div>
            </CrossLink>
          </div>
        </div>

        {(alias !== 'glenorchy' ) && (
          <div className="row">
            <div className="col-12 anchor-point" id="how-are-households-forecast-to-change">
              <HouseholdForecastChart
                data={this.props.data.forecastHouseholds}
                areaName={LGAName}
                color={
                  typeof window !== `undefined` &&
                  getComputedStyle(document.body).getPropertyValue('--bs-primary')
                }
                dataNotes={this.props.data.forecastHouseholdsNotes}
                chartInfo={this.props.data.forecastHouseholdsInfo}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12 anchor-point" id="what-are-the-dominant-and-emerging-tenure-types">
            <TenureChart
              data={this.props.data.tenure}
              benchmarkData={this.props.data.tenure_BM}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              color={
                typeof window !== `undefined` &&
                getComputedStyle(document.body).getPropertyValue('--bs-primary')
              }
              dataNotes={this.props.data.tenureTypesNotes}
              chartInfo={this.props.data.tenureInfo}
            />
          </div>
          <div className="col-12">
            <CrossLink
              urls={tenureCrossLinks}
              product="atlas"
              icon="map"
              clientProducts={this.props.pageContext.productLinks}
            >
              <div className="cross-link__title">
                Where are different tenure types most prominent?
              </div>
              <div className="cross-link__sub-title">Collection of maps</div>
            </CrossLink>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <TenureChangeChart
              data={this.props.data.tenure}
              benchmarkData={this.props.data.tenure_BM}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              color={
                typeof window !== `undefined` &&
                getComputedStyle(document.body).getPropertyValue('--bs-primary')
              }
              dataNotes={this.props.data.tenureChangeNotes}
              chartInfo={this.props.data.tenureInfo}
            />
          </div>
        </div>
      </PageContentWrapper>
    )
  }
}

export default PopulationHouseholdsTemplate

export const dataNoteFragment = graphql`
  fragment DataNoteFragment on MarkdownRemark {
    html
    frontmatter {
      title
      subtitle
    }
  }
`

export const PageQuery = graphql`
  query PopulationHouseholdsByLGA($lgacode: String!, $benchmarkCode: String!, $geocode: String!) {
    populationForecast: forecastPopulationXlsxResult(Area_Id: { eq: $lgacode }) {
      Population_06
      Population_11
      Population_16
      Population_21
      Population_26
      Population_31
      Population_36
      Population_41
      Annual_Growth_16_36
      Change_16_36
      Annual_Growth_16_41
      Change_16_41
      Source
    }

    ageGroups: erpByAgeMasterXlsxAgeGroups(Area_Id: { eq: $lgacode }) {
      N_0to4
      Per_0to4
      N_5to9
      Per_5to9
      N_10to14
      Per_10to14
      N_15to19
      Per_15to19
      N_20to24
      Per_20to24
      N_25to29
      Per_25to29
      N_30to34
      Per_30to34
      N_35to39
      Per_35to39
      N_40to44
      Per_40to44
      N_45to49
      Per_45to49
      N_50to54
      Per_50to54
      N_55to59
      Per_55to59
      N_60to64
      Per_60to64
      N_65to69
      Per_65to69
      Per_65to69
      N_70to74
      Per_70to74
      N_75to79
      Per_75to79
      N_80to84
      Per_80to84
      N_85plus
      Per_85plus
      Total
    }

    ageGroupsBM: erpByAgeMasterXlsxAgeGroups(Area_Id: { eq: $benchmarkCode }) {
      N_0to4
      Per_0to4
      N_5to9
      Per_5to9
      N_10to14
      Per_10to14
      N_15to19
      Per_15to19
      N_20to24
      Per_20to24
      N_25to29
      Per_25to29
      N_30to34
      Per_30to34
      N_35to39
      Per_35to39
      N_40to44
      Per_40to44
      N_45to49
      Per_45to49
      N_50to54
      Per_50to54
      N_55to59
      Per_55to59
      N_60to64
      Per_60to64
      N_65to69
      Per_65to69
      Per_65to69
      N_70to74
      Per_70to74
      N_75to79
      Per_75to79
      N_80to84
      Per_80to84
      N_85plus
      Per_85plus
      Total
    }

    ageChange: erpByAgeMasterXlsx5YearChange(Area_Id: { eq: $lgacode }) {
      Area_Id
      Change_0to4
      Change_5to9
      Change_10to14
      Change_15to19
      Change_20to24
      Change_25to29
      Change_30to34
      Change_35to39
      Change_40to44
      Change_45to49
      Change_50to54
      Change_55to59
      Change_60to64
      Change_65to69
      Change_70to74
      Change_75to79
      Change_80to84
      Change_85plus
    }

    householdsXlsxHouseholdTypes(Area_Id: { eq: $lgacode }) {
      lone_person_21_num
      lone_person_21_per
      couple_21_num
      couple_21_per
      family_21_num
      family_21_per
      group_21_num
      group_21_per
      total_21
    }

    householdTypesBM: householdsXlsxHouseholdTypes(Area_Id: { eq: $benchmarkCode }) {
      lone_person_21_num
      lone_person_21_per
      couple_21_num
      couple_21_per
      family_21_num
      family_21_per
      group_21_num
      group_21_per
      total_21
    }

    tenure: householdsXlsxTenure(Area_Id: { eq: $lgacode }) {
      N2021_Rented_Private
      Per2021_Rented_Private
      N2021_Rented_Social
      Per2021_Rented_Social
      N2021_Owned_Outright
      Per2021_Owned_Outright
      N2021_Owned_With_Mortgage
      Per2021_Owned_With_Mortgage
      N2021_Total
      N2016_Rented_Private
      Per2016_Rented_Private
      N2016_Rented_Social
      Per2016_Rented_Social
      N2016_Owned_Outright
      Per2016_Owned_Outright
      N2016_Owned_With_Mortgage
      Per2016_Owned_With_Mortgage
      N2016_Total
    }

    tenure_BM: householdsXlsxTenure(Area_Id: { eq: $benchmarkCode }) {
      N2021_Rented_Private
      Per2021_Rented_Private
      N2021_Rented_Social
      Per2021_Rented_Social
      N2021_Owned_Outright
      Per2021_Owned_Outright
      N2021_Owned_With_Mortgage
      Per2021_Owned_With_Mortgage
      N2021_Total
      N2016_Rented_Private
      Per2016_Rented_Private
      N2016_Rented_Social
      Per2016_Rented_Social
      N2016_Owned_Outright
      Per2016_Owned_Outright
      N2016_Owned_With_Mortgage
      Per2016_Owned_With_Mortgage
      N2016_Total
    }

    forecastHouseholds: allForecastHouseholdsXlsxResult(filter: { Area_Id: { eq: $lgacode } }) {
      edges {
        node {
          Area_Id
          LGAName
          Type
          Year
          Number
          Source
        }
      }
    }

    areas2XlsxAreas(Area_Id: { eq: $lgacode }) {
      Name
      LongName
      Geocode
      Area_Id
      GCC_Code
      GCC_Name
      Alias
    }

    allFile(filter: { name: { eq: $geocode } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    populationForecastNotes: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "population-forecast" }, type: { eq: "full-text" } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            sourceText
            sourceID
          }
          ...DataNoteFragment
        }
      }
    }

    populationAgeNotes: markdownRemark(
      frontmatter: { slug: { eq: "population-by-age" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    populationAgeInfo: markdownRemark(
      frontmatter: { slug: { eq: "population-by-age" }, type: { eq: "chart-info" } }
    ) {
      ...DataNoteFragment
    }

    householdTypeNotes: markdownRemark(
      frontmatter: { slug: { eq: "household-types" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    householdTypeInfo: markdownRemark(
      frontmatter: { slug: { eq: "household-mix" }, type: { eq: "chart-info" } }
    ) {
      ...DataNoteFragment
    }

    forecastHouseholdsNotes: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "forecast-households" }, type: { eq: "full-text" } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            sourceText
            sourceID
          }
          ...DataNoteFragment
        }
      }
    }

    forecastHouseholdsInfo: markdownRemark(
      frontmatter: { slug: { eq: "forecast-households" }, type: { eq: "chart-info" } }
    ) {
      ...DataNoteFragment
    }

    tenureTypesNotes: markdownRemark(
      frontmatter: { slug: { eq: "tenure-types" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    tenureChangeNotes: markdownRemark(
      frontmatter: { slug: { eq: "tenure-change" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    tenureInfo: markdownRemark(
      frontmatter: { slug: { eq: "tenure" }, type: { eq: "chart-info" } }
    ) {
      ...DataNoteFragment
    }

    HouseholdsByDwellingTypeNotes: markdownRemark(
      frontmatter: { slug: { eq: "households-by-dwelling-type" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }
  }
`
