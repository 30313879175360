const Proptrack =
  'Compiled and presented by .id (informed decisions) using data from PropTrack Pty Ltd (ABN 43 127 386 298). Updated twice annually'
const PropTrackLongVersion =
  'PropTrack (REA Group housing listings, 12 months ending selected period, compared to inflation adjusted 2021 Census income. Calculated and presented by .id (informed decisions)'
const ABS2006 =
  'ABS Census of Population and Housing, 2021, 2016, 2011 and 2006. Compiled and presented by .id (informed decisions)'
const ABS2011 =
  'ABS Census of Population and Housing, 2021, 2016 and 2011. Compiled and presented by .id (informed decisions)'
const ABS2016 =
  'ABS Census of Population and Housing, 2021 and 2016. Compiled and presented by .id (informed decisions)'
const ABS2021 =
  'ABS Census of Population and Housing, 2021. Compiled and presented by .id (informed decisions)'
const ABSRegional = 'ABS Regional population by age and sex, 2022'
const ABSRegionalChange = 'ABS Regional population by age and sex, 2017-2022'
const BuildingApproval = 'ABS Building approvals and .id development data'
const ChartBuildingApproval = 'ABS, Building Approvals Australia, 8731.0 monthly release'
const MapBuildingApproval =
  'ABS, Building Approvals, Australia SA1 level unpublished data - quarterly release'
const Homelessness = 'ABS Estimating Homelessness 2021 and ABS Census 2021'
const FinancialYearChartLabel = 'Year ended June'

export {
  Proptrack,
  PropTrackLongVersion,
  ABS2006,
  ABS2011,
  ABS2016,
  ABS2021,
  ABSRegional,
  ABSRegionalChange,
  BuildingApproval,
  ChartBuildingApproval,
  MapBuildingApproval,
  Homelessness,
  FinancialYearChartLabel,
}
