import { first, find, map } from 'lodash'
import React from 'react'
import { graphql } from 'gatsby'
import PageContentWrapper from '../components/pageContentWrapper'
import HouseholdsByDwellingTypeChart from '../components/charts/households-by-dwelling-type.js'
import OccupancyRateByNumberOfBedroomsChart from '../components/charts/occupancy-rate-by-number-of-bedrooms.js'
import OccupancyRateByNumberOfBedroomsChangeChart from '../components/charts/occupancy-rate-by-number-of-bedrooms-change.js'
import HousingConsumptionDwellings from '../components/charts/housing-consumption-dwellings.js'
import HousingConsumptionSuburb from '../components/charts/housing-consumption-suburb.js'
import HousingConsumptionPatternsChart from '../components/charts/housing-consumption-patterns.js'
import OccupancyRatePerBedroom from '../components/dynamicText/housing-consumption/occupancy-rate-by-number-of-bedrooms'
import HousingConsumptionPatternInfo from '../components/dynamicText/housing-consumption/housing-consumption-pattern'
import HousingConsumptionDwellingsInfo from '../components/dynamicText/housing-consumption/housing-consumption-dwellings'
import HouseholdsByDwellingTypeChartInfo from '../components/dynamicText/housing-consumption/housing-consumption-dwelling-types'
import DynamicText from '../components/dynamicText'
import SectionUpfrontText from '../components/collapsibleUpfrontText/section-upfront-text'

const householdTypes = [
  {
    name: 'Lone person',
    value: 'Lone_Person',
  },
  { name: 'Couple without children', value: 'Couple_Without_Children' },
  { name: 'Couple with children', value: 'Couple_With_Children' },
  { name: 'One parent family', value: 'One_Parent_Family' },
  { name: 'Other family', value: 'Other_Family' },
  { name: 'Group', value: 'Group_Household' },
]

class HousingConsumptionTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      householdType: first(householdTypes).value,
    }
  }

  changeHouseholdType = value => {
    this.setState({ householdType: value })
  }

  handleHouseholdTypeDropdownOnChange = event => {
    this.changeHouseholdType(event.target.value)
  }

  render() {

    const title = this.props.pageContext.title
    const slug = this.props.pageContext.slug
    const LGAName = this.props.data.areas2XlsxAreas.Name
    const LGALongName = this.props.data.areas2XlsxAreas.LongName
    const alias = this.props.data.areas2XlsxAreas.Alias
    const benchmarkName = this.props.data.areas2XlsxAreas.GCC_Name

    const housingConsumptionLga = this.props.data.housingConsumptionLga.nodes[0].data
    const housingConsumptionSmallArea = this.props.data.housingConsumptionSmallArea.nodes[0].data
    const housingConsumptionBm = this.props.data.housingConsumptionBm.nodes[0].data
    

    const selectedHouseholdType = find(householdTypes, [
      'value',
      this.state.householdType,
    ])


    return (
      <PageContentWrapper
        title={title}
        LGAName={LGALongName}
        theme={this.props.pageContext.theme}
        areas={this.props.data.areas2XlsxAreas}
        slug={slug}
        alias={alias}
        productLinks={this.props.pageContext.productLinks}
        clientLogo={this.props.data.allFile}
      >
        <SectionUpfrontText title="Housing consumption" type="h2">
          <p>
            While there is little qualitative data on housing preference, Census data enables detailed analysis
            of dwelling consumption by household type - that is, the types of dwellings different household
            types are residing in. This is known as “revealed preference” as it shows who lives where in the real
            world. Note that while there may be some level of preference in this, in some cases it is more a
            function of the housing stock which is available in the area, and this can be seen in comparison to
            the benchmark. “Expressed preference”, in contrast to revealed preference, can be examined by the use
            of survey and focus group data.
          </p>
          <p>
            It is important to understand housing consumption from a housing context, as it reveals the area's
            residential role and function, era of settlement and provides key insights into the level of demand
            for various housing types. This page shows a breakdown of household types by the types of dwellings
            they occupy, broken down by numbers of bedrooms, and occupancy rate. further down the page you can
            select a household type for more detailed analysis of dwelling types.
          </p>
        </SectionUpfrontText>

        <div className="row">
          <div className="col-12 anchor-point" id="who-lives-in-what-type-of-housing">
            <HouseholdsByDwellingTypeChart
              data={this.props.data.householdByDwelling}
              benchmarkData={this.props.data.householdByDwellingBM}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              chartInfo={DynamicText(
                <HouseholdsByDwellingTypeChartInfo benchmarkName={benchmarkName} LGAName={LGAName} />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 anchor-point" id="what-is-the-household-size-for-each-dwelling-type">
            <OccupancyRateByNumberOfBedroomsChart
              data={this.props.data.occupancyRateByNumberOfBedrooms}
              benchmarkData={this.props.data.occupancyRateByNumberOfBedroomsBM}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              dataNotes={DynamicText(
                <OccupancyRatePerBedroom benchmarkName={benchmarkName} />
              )}
              chartInfo={this.props.data.occupancyRateNote}
            />
          </div>
          <div className="col-md-6 anchor-point" id="what-is-the-household-change-for-each-dwelling-type">
            <OccupancyRateByNumberOfBedroomsChangeChart
              data={this.props.data.occupancyRateByNumberOfBedroomsChange}
              benchmarkData={
                this.props.data.occupancyRateByNumberOfBedroomsChangeBM
              }
              areaName={LGAName}
              benchmarkName={benchmarkName}
              dataNotes={DynamicText(
                <OccupancyRatePerBedroom benchmarkName={benchmarkName} />
              )}
              chartInfo={this.props.data.occupancyRateNote}
            />
          </div>
        </div>

        <div className="row sticky-div">
          <div className="col-12">
            <div className="p-3 mb-3 content">
              <h5
                className="anchor-point"
                id="what-type-of-dwellings-do-specific-household-types-live-in"
              >
                Select a household type for further analysis
              </h5>
              <select
                name="household-type"
                onChange={this.handleHouseholdTypeDropdownOnChange}
                className="form-select"
                title="Household type"
              >
                {map(householdTypes, householdType => (
                  <option value={householdType.value} key={householdType.value}>
                    {householdType.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <HousingConsumptionDwellings
              data={housingConsumptionLga.Years.Year2021}
              benchmarkData={housingConsumptionBm.Years.Year2021}
              areaName={LGAName}
              dataNotes={this.props.data.housingTypesByBedroomsNotes}
              houseHoldType={selectedHouseholdType}
              benchmarkName={benchmarkName}
              chartInfo={
                DynamicText(
                  <HousingConsumptionDwellingsInfo
                    houseHoldType={selectedHouseholdType.name}
                    LGAName={LGAName}
                    clientAlias={alias} />
                )
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 anchor-point" id="how-have-housing-consumption-patterns-changed">
            <HousingConsumptionPatternsChart
              data={housingConsumptionLga.ChangesBetweenYears.Changes2016to2021}
              areaName={LGAName}
              dataNotes={this.props.data.housingTypesByBedroomsNotes}
              houseHoldType={selectedHouseholdType}
            />
          </div>
        </div>  

        <div className="row">
          <div className="col-12 anchor-point" id="how-do-housing-consumption-patterns-differ-by-area">
            <HousingConsumptionSuburb
              data={housingConsumptionSmallArea.SmallAreas?.Year2021}
              areaName={LGAName}          
              dataNotes={
                DynamicText(
                  <HousingConsumptionPatternInfo LGAName={LGAName} />
                )
              }
              houseHoldType={selectedHouseholdType}
            />
          </div>
        </div>
      </PageContentWrapper>
    )
  }
}

export default HousingConsumptionTemplate

export const dataNoteFragment = graphql`
  fragment DataNoteFragment on MarkdownRemark {
    html
    frontmatter {
      title
      subtitle
    }
  }
`

export const PageQuery = graphql`
  query HousingConsumption(
    $lgacode: String!
    $benchmarkCode: String!
    $geocode: String!
  ) {
    areas2XlsxAreas(Area_Id: { eq: $lgacode }) {
      Name
      LongName
      Geocode
      Area_Id
      GCC_Code
      GCC_Name
      Alias
    }

    householdTypeNotes: markdownRemark(
      frontmatter: {
        slug: { eq: "household-types" }
        type: { eq: "full-text" }
      }
    ) {
      ...DataNoteFragment
    }

    householdByDwelling: householdsByDwellingXlsxResult(
      Area_Id: { eq: $lgacode }
    ) {
      Area_Id
      Lone_person_High_density
      Lone_person_High_density_Per
      Lone_person_Medium_density
      Lone_person_Medium_density_Per
      Lone_person_Separate_house
      Lone_person_Separate_house_Per
      Group_High_density
      Group_High_density_Per
      Group_Medium_density
      Group_Medium_density_Per
      Group_Separate_house
      Group_Separate_house_Per
      Family_High_density
      Family_High_density_Per
      Family_Medium_density
      Family_Medium_density_Per
      Family_Separate_house
      Family_Separate_house_Per
      Couple_High_density
      Couple_High_density_Per
      Couple_Medium_density
      Couple_Medium_density_Per
      Couple_Separate_house
      Couple_Separate_house_Per
    }

    householdByDwellingBM: householdsByDwellingXlsxResult(
      Area_Id: { eq: $benchmarkCode }
    ) {
      Area_Id
      Lone_person_High_density
      Lone_person_High_density_Per
      Lone_person_Medium_density
      Lone_person_Medium_density_Per
      Lone_person_Separate_house
      Lone_person_Separate_house_Per
      Group_High_density
      Group_High_density_Per
      Group_Medium_density
      Group_Medium_density_Per
      Group_Separate_house
      Group_Separate_house_Per
      Family_High_density
      Family_High_density_Per
      Family_Medium_density
      Family_Medium_density_Per
      Family_Separate_house
      Family_Separate_house_Per
      Couple_High_density
      Couple_High_density_Per
      Couple_Medium_density
      Couple_Medium_density_Per
      Couple_Separate_house
      Couple_Separate_house_Per
    }

    occupancyRateByNumberOfBedrooms: occupancyRateByNumberOfBedroomsXlsxOccupancy(
      Area_Id: { eq: $lgacode }
    ) {
      Area_Id
      LGA_name
      _2021_persons_per_dwelling_0_or_1_bedroom
      _2021_persons_per_dwelling_two_bedrooms
      _2021_persons_per_dwelling_three_bedrooms
      _2021_persons_per_dwelling_4_or_more_bedrooms
      _2016_persons_per_dwelling_0_or_1_bedroom
      _2016_persons_per_dwelling_two_bedrooms
      _2016_persons_per_dwelling_three_bedrooms
      _2016_persons_per_dwelling_4_or_more_bedrooms
      _2016_persons_0_or_1_bedroom
      _2016_persons_two_bedrooms
      _2016_persons_three_bedrooms
      _2016_persons_4_or_more_bedrooms
      _2021_persons_0_or_1_bedroom
      _2021_persons_two_bedrooms
      _2021_persons_three_bedrooms
      _2021_persons_4_or_more_bedrooms
    }

    occupancyRateByNumberOfBedroomsBM: occupancyRateByNumberOfBedroomsXlsxOccupancy(
      Area_Id: { eq: $benchmarkCode }
    ) {
      Area_Id
      LGA_name
      _2021_persons_per_dwelling_0_or_1_bedroom
      _2021_persons_per_dwelling_two_bedrooms
      _2021_persons_per_dwelling_three_bedrooms
      _2021_persons_per_dwelling_4_or_more_bedrooms
      _2016_persons_per_dwelling_0_or_1_bedroom
      _2016_persons_per_dwelling_two_bedrooms
      _2016_persons_per_dwelling_three_bedrooms
      _2016_persons_per_dwelling_4_or_more_bedrooms
    }

    occupancyRateByNumberOfBedroomsChange: occupancyRateByNumberOfBedroomsXlsxOccupancy(
      Area_Id: { eq: $lgacode }
    ) {
      Area_Id
      LGA_name
      _2016_2021_change_per_dwelling_0_or_1_bedroom
      _2016_2021_change_per_dwelling_two_bedrooms
      _2016_2021_change_per_dwelling_three_bedrooms
      _2016_2021_change_per_dwelling_4_or_more_bedrooms
    }

    occupancyRateByNumberOfBedroomsChangeBM: occupancyRateByNumberOfBedroomsXlsxOccupancy(
      Area_Id: { eq: $benchmarkCode }
    ) {
      Area_Id
      LGA_name
      _2016_2021_change_per_dwelling_0_or_1_bedroom
      _2016_2021_change_per_dwelling_two_bedrooms
      _2016_2021_change_per_dwelling_three_bedrooms
      _2016_2021_change_per_dwelling_4_or_more_bedrooms
    }
 
 
    housingConsumptionBm: allHousingConsumptionData(filter: {data: {Area_Id: {eq: $benchmarkCode}}}) {
      nodes {
        data {
          Area_Id
          Years {
            Year2021 {
              Couple_With_Children
              Couple_With_Children_Percentage
              Couple_Without_Children
              Couple_Without_Children_Percentage
              Dwelling_Composition
              Dwelling_Type
              Group_Household_Percentage
              Group_Household
              Lone_Person
              Lone_Person_Percentage
              One_Parent_Family
              One_Parent_Family_Percentage
              Other_Family
              Other_Family_Percentage
              Total
              Total_Percentage
            }
          }         
        }
      }
    }
    
    housingConsumptionLga: allHousingConsumptionData(filter: {data: {Area_Id: {eq: $lgacode}}}) {
      nodes {
        data {
          Area_Id
          Years {
            Year2021 {
              Couple_With_Children
              Couple_With_Children_Percentage
              Couple_Without_Children
              Couple_Without_Children_Percentage
              Dwelling_Composition
              Dwelling_Type
              Group_Household
              Group_Household_Percentage
              Lone_Person
              Lone_Person_Percentage
              One_Parent_Family
              One_Parent_Family_Percentage
              Other_Family
              Other_Family_Percentage
              Total
              Total_Percentage
            }          
          }       
       
          ChangesBetweenYears {
            Changes2016to2021 {
              Couple_With_Children
              Couple_With_Children_Percentage
              Couple_Without_Children
              Couple_Without_Children_Percentage
              Dwelling_Composition
              Dwelling_Type
              Lone_Person
              Group_Household
              Group_Household_Percentage
              Lone_Person_Percentage
              One_Parent_Family
              One_Parent_Family_Percentage
              Other_Family
              Other_Family_Percentage
              Total
              Total_Percentage
            }
          }        
        }
      }
    }   

    housingConsumptionSmallArea: allHousingConsumptionSmallAreaData(filter: {data: {Area_Id: {eq: $lgacode}}}) {
      nodes {
        data {
          Area_Id
          SmallAreas {
            Year2021 {
              Area_Name
              Couple_With_Children
              Couple_With_Children_Percentage
              Couple_Without_Children
              Couple_Without_Children_Percentage
              Dwelling_Composition
              Dwelling_Type
              Group_Household_Percentage
              Group_Household
              Lone_Person
              Lone_Person_Percentage
              One_Parent_Family
              One_Parent_Family_Percentage
              Other_Family
              Other_Family_Percentage
              Total
              Total_Percentage
            }
          }             
        }
      }
    }   
   
    allFile(filter: { name: { eq: $geocode } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    housingTypesByBedroomsNotes: markdownRemark(
      frontmatter: {
        slug: { eq: "housing-types-by-bedrooms" }
        type: { eq: "full-text" }
      }
    ) {
      ...DataNoteFragment
    }
    
    occupancyRateNote: markdownRemark(
      frontmatter: {
        slug: { eq: "occupancy-rate" }
        type: { eq: "chart-info" }
      }
    ) {
      ...DataNoteFragment
    }
  }
`
