import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { isEmpty } from 'lodash'
import { Button, ButtonGroup } from 'reactstrap'
import numeral from 'numeral'
import ChartWrapper from './../chartWrapper/index'
import formatPercentage from '../../functions/formatPercentage'
import TooltipWrapper from '../tooltip-wrapper'
import * as Datasource from '../../config/text-constants'
import { ErrorMessageChart } from '../error-message-chart'

class AgeGroupsChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataType: 'percent',
    }
  }

  changeToPercent = () => {
    this.setState({ dataType: 'percent' })
  }

  changeToNumber = () => {
    this.setState({ dataType: 'number' })
  }

  render = () => {
    try {
      if (isEmpty(this.props.data)) {
        return <ErrorMessageChart error="No data for chart" />
      }
      const chartData = [
        {
          name: '0-4',
          number: this.props.data.N_0to4,
          perc: this.props.data.Per_0to4,
          bm: this.props.benchmarkData.Per_0to4,
        },
        {
          name: '5-9',
          number: this.props.data.N_5to9,
          perc: this.props.data.Per_5to9,
          bm: this.props.benchmarkData.Per_5to9,
        },
        {
          name: '10-14',
          number: this.props.data.N_10to14,
          perc: this.props.data.Per_10to14,
          bm: this.props.benchmarkData.Per_10to14,
        },
        {
          name: '15-19',
          number: this.props.data.N_15to19,
          perc: this.props.data.Per_15to19,
          bm: this.props.benchmarkData.Per_15to19,
        },
        {
          name: '20-24',
          number: this.props.data.N_20to24,
          perc: this.props.data.Per_20to24,
          bm: this.props.benchmarkData.Per_20to24,
        },
        {
          name: '25-29',
          number: this.props.data.N_25to29,
          perc: this.props.data.Per_25to29,
          bm: this.props.benchmarkData.Per_25to29,
        },
        {
          name: '30-34',
          number: this.props.data.N_30to34,
          perc: this.props.data.Per_30to34,
          bm: this.props.benchmarkData.Per_30to34,
        },
        {
          name: '35-39',
          number: this.props.data.N_35to39,
          perc: this.props.data.Per_35to39,
          bm: this.props.benchmarkData.Per_35to39,
        },
        {
          name: '40-44',
          number: this.props.data.N_40to44,
          perc: this.props.data.Per_40to44,
          bm: this.props.benchmarkData.Per_40to44,
        },
        {
          name: '45-49',
          number: this.props.data.N_45to49,
          perc: this.props.data.Per_45to49,
          bm: this.props.benchmarkData.Per_45to49,
        },
        {
          name: '50-54',
          number: this.props.data.N_50to54,
          perc: this.props.data.Per_50to54,
          bm: this.props.benchmarkData.Per_50to54,
        },
        {
          name: '55-59',
          number: this.props.data.N_55to59,
          perc: this.props.data.Per_55to59,
          bm: this.props.benchmarkData.Per_55to59,
        },
        {
          name: '60-64',
          number: this.props.data.N_60to64,
          perc: this.props.data.Per_60to64,
          bm: this.props.benchmarkData.Per_60to64,
        },
        {
          name: '65-69',
          number: this.props.data.N_65to69,
          perc: this.props.data.Per_65to69,
          bm: this.props.benchmarkData.Per_65to69,
        },
        {
          name: '70-74',
          number: this.props.data.N_70to74,
          perc: this.props.data.Per_70to74,
          bm: this.props.benchmarkData.Per_70to74,
        },
        {
          name: '75-79',
          number: this.props.data.N_75to79,
          perc: this.props.data.Per_75to79,
          bm: this.props.benchmarkData.Per_75to79,
        },
        {
          name: '80-84',
          number: this.props.data.N_80to84,
          perc: this.props.data.Per_80to84,
          bm: this.props.benchmarkData.Per_80to84,
        },
        {
          name: '85+',
          number: this.props.data.N_85plus,
          perc: this.props.data.Per_85plus,
          bm: this.props.benchmarkData.Per_85plus,
        },
      ]

      const numberFormatter = value =>
        this.state.dataType === 'percent' ? value : numeral(value).format('0,0')

      const CustomTooltip = props => {
        const { active } = props

        if (active) {
          const { payload, label } = props

          return (
            <TooltipWrapper>
              <strong>Age: {label}</strong>
              {payload.map((item, key) => (
                <p key={key} className="recharts-tooltip-label m-0" style={{ color: item.color }}>
                  {item.name}:{' '}
                  {this.state.dataType === 'percent'
                    ? formatPercentage(item.value)
                    : numeral(item.value).format('0,0')}
                </p>
              ))}
            </TooltipWrapper>
          )
        }
        return null
      }

      return (
        <ChartWrapper
          name={`5-year-age-structure-chart`}
          title={`What is the age profile?`}
          subTitle={`Estimated Resident Population by age, June 30th, 2022 (revised)`}
          dataSource={Datasource.ABSRegional}
          dataNotes={this.props.dataNotes}
          chartInfo={this.props.chartInfo}
          body={
            <div>
              <ButtonGroup size="sm" className="d-print-none">
                <Button
                  outline
                  onClick={this.changeToPercent}
                  active={this.state.dataType === 'percent'}
                >
                  Percent
                </Button>
                <Button
                  outline
                  onClick={this.changeToNumber}
                  active={this.state.dataType === 'number'}
                >
                  Number
                </Button>
              </ButtonGroup>
              <div className="chart__viz" style={{ width: '100%', height: 640 }}>
                <ResponsiveContainer>
                  <BarChart data={chartData} barGap={0}>
                    <XAxis
                      dataKey="name"
                      tickLine={false}
                      type="category"
                      interval={0}
                      angle={-45}
                      textAnchor="end"
                    />
                    <YAxis
                      tickLine={false}
                      unit={this.state.dataType === 'percent' ? '%' : ''}
                      type="number"
                      tickFormatter={numberFormatter}
                    />
                    <CartesianGrid vertical={false} horizontal={true} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      verticalAlign="top"
                      align="left"
                      height={50}
                      formatter={value => <span style={{ color: 'black' }}>{value}</span>}
                    />
                    <Bar
                      dataKey={this.state.dataType === 'percent' ? 'perc' : 'number'}
                      name={this.props.areaName}
                      fill={
                        typeof window !== `undefined` &&
                        getComputedStyle(document.body).getPropertyValue('--bs-primary')
                      }
                    />
                    {this.state.dataType === 'percent' && (
                      <Bar
                        dataKey="bm"
                        name={this.props.benchmarkName}
                        fill={
                          typeof window !== `undefined` &&
                          getComputedStyle(document.body).getPropertyValue('--bs-gray-500')
                        }
                      />
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        />
      )
    } catch (error) {
      return <ErrorMessageChart error={error} />
    }
  }
}

export default AgeGroupsChart
